export const blogPostData = [
  {
    id: 1,
    writers: " Happy Khumalo",
    name: "Rehad Desai",
    trailerName: "Time of Pandemics - Documentary",
    professionTitle: "Documentary filmmaker",
    bannerSubTitle: "Rehad Desai is a South African documentary",
    youtubeLink: "https://www.youtube.com/watch?v=oeB7VPU17dU",
    mainTitle:
      "Rehad Desai is a South African documentary filmmaker who is known for his multi-award-winning documentaries",
    paragraphs: [
      "Born in the Mother City of Cape Town, Western Cape in South Africa before being forced to live in London, United Kingdom - Rehad Desai is a South African documentary filmmaker who is known for his multi-award-winning documentaries like <a href='https://www.youtube.com/watch?v=_sEYKDdiQwA&t=11s' target=”_blank”>Born into Struggle (2004)</a>, <a href='https://www.youtube.com/watch?v=pkK_T_vz7cY' target=”_blank”>Miners Shot Down (2014)</a>, <a href='https://www.youtube.com/watch?v=OpGYyCLb9xQ' target=”_blank”>Everything Must Fall (2018)</a>, <a href='https://www.youtube.com/watch?v=-IeUE4jAGsQ' target=”_blank”>How to Steal a Country (2019)</a>, and other myriad documentaries. Rehad’s father who was the president of the Coloured People’s Congress (CPC) in Cape Town at the time was declared a dangerous communist and sent to exile with other comrades in the 1960s. According to Rehad, growing up in a foreign country was not easy, notably when people from your country suffer and were getting detained by their government. Together with his mother and siblings, they had no choice but to leave South Africa and follow their father to exile. Seeing how his father was determined to fight for his people, Rehad developed the same passion while still a teenager.",
      "This icon returned to South Africa in 1990, where he was active in journalism before making documentary films. He produced current affairs and insets for E-TV and SABC thus gaining hundreds of television hours. He went on to complete his master’s degree in social history and film and television courses at Eurodoc in Europe. He further enhanced his knowledge of documentary filmmaking through reading and watching different movies as a way to pave his career in filmmaking.",
      "In the last 2 decade and a half, Rehad has used documentaries to expose the struggle imposed on his native nation. He was motivated by narratives from different individuals who were detained, sent to exile, or punished with movement restriction orders and other unjust forms of punishment during the active years of the apartheid era. He started with his childhood documentary, Born into Struggle (2004). This 74-minute documentary takes us on a journey mapped out by the scars scratched into his family's life from having a father who was intensely involved in politics. He believed that there was not enough media space to reflect on what the people of South Africa went through during that era; hence, documentary films were the best option to change some of the unjust situations in the country without guns. His documentaries addressed a number of issues faced by black people working in different fields, including mines. Unlike other film makers, his filmography remains consistent and real, looking at the titles of his work like Taking Back the Waves (2005), The Battle of Johannesburg (2010), and more. He applauds his team for their incredible effort throughout the projects.",
      "Desai runs his own company,<a href='https://uhuruproductions.co.za/our-team/' target=”_blank”>Uhuru Productions</a>, where most of the documentaries are produced. Ten years ago, he left Tri-Continental Film Festival as a director and extended his hand into other filmmaking initiatives such as the Documentary Filmmakers’ Association (DFA) and the South African Screen Federation (SASF) as a founding member. The main aim was to nurture the industry.",
      "In 2014, Desai released a brilliant documentary called <a href='https://www.youtube.com/watch?v=pkK_T_vz7cY' target=”_blank”>Miners Shot Down</a>. This 86-minute documentary film was awarded several awards, such as an International Emmy Award for Best Documentary, which makes Miners Shot Down the most awarded documentary in the industry in South Africa. Miners Shot Down is based on a 2012 massacre where mine workers at a platinum mine in Marikana were killed by policemen during a strike.",
      "He encountered several challenges when working on this project such as convincing participants from the mine to take part since they were still in the negative eye of the media and being blamed for embarking on such a terrible strike. It took him 18 months to complete this award-winning documentary, as he was in the North West province researching South African mines when the massacre took place. He extended his kindness to the affected families by showing support and participating in fund-raising for legal representatives. One may regard Rehad as an icon of art, looking at the impact of his transition from being an activist to making films with stories that directly affect society.",
      "The icon hopes to be remembered through the quality of his work rather than the quantity; he gave voice to the voiceless, especially victims of the previous government system. His documentaries presented stories of society, which shall remember the power of his work in levelling up inequality and freedom of expression regardless of skin colour, language, or gender. Furthermore, Desai believes that his films succeeded in providing a voice and support to the victims, including mine workers through Miners Shot Down (2014), and the student revolution through the <a href='https://www.youtube.com/watch?v=OpGYyCLb9xQ' target=”_blank”>Everything Must Fall documentary (2018)</a> which was motivated by the Fees Must Fall student campaign in October 2015. He went on to shoot <a href='https://www.youtube.com/watch?v=-IeUE4jAGsQ' target=”_blank”>How to Steal a Country (2019)</a>, a documentary about the role of investigative journalists in South Africa looking at the politics. He is so proud of his work and contributions towards the stability of the country through the arts.",
      "Research, reading, watching different movies, and education are the crucial aspects of the industry; therefore, his best advice to students who have a passion to tell stories and change the world is to follow these routes.",
    ],
    profileImage: require("assets/img/BlogsImages/Rehad/profile.jpeg"),
    bannerImage: require("assets/img/BlogsImages/Rehad/banner.jpeg"),
    carouselImages: [
      require("assets/img/BlogsImages/Rehad/1.jpeg"),
      require("assets/img/BlogsImages/Rehad/2.jpeg"),
      require("assets/img/BlogsImages/Rehad/3.jpeg"),
    ],
  },
  {
    id: 2,
    writers: "Mmapowana Masete",
    name: "Tope Oshin",
    trailerName: "Here Love Lies",
    professionTitle: "Actress | Director | Producer",
    bannerSubTitle:
      " In the pursuit of 'love and forever', single parent and Travel Blogger Amanda, embarks on a social media romance with American Tour Guide Michael. A trip to the US to meet up for the first time, opens up more doors than both anticipated.",
    youtubeLink: "https://www.youtube.com/watch?v=ADosZWg40Qk",
    mainTitle:
      "We have the opportunity to shed light on marginalized experiences and challenge societal norms about our culture and our climate.",
    paragraphs: [
      "Tope Oshin stands out in the realm of Nigerian cinema as a trailblazing director who has not only built a name for herself but is also pushing women to break down boundaries and make their mark in the film business. Tope Oshin has been crucial in questioning gender conventions, fostering diversity, and amplifying the voices of underrepresented groups through her work. Tope Oshin has been a beacon of inspiration and change in Nigerian and African cinema through her creative process, cautious project selection, and attention to representation.",
      "Tope’s path to becoming a notable director is formed by a combination of film-related education, practical experience, and lifelong learning. She obtained extensive film training from prestigious institutions like London's Met Film School, Denver's Colorado Film School, and Lagos State University, where she studied theater, TV, and Film Studies. Over the past two decades, Tope has also actively engaged in development workshops, film labs, and fellowships, always developing her craft and staying abreast of the evolving world of film and storytelling.",
      "Oshin has received numerous prizes and plaudits for her unique storytelling ability and devotion to pushing limits throughout her career. Oshin began her career in entertainment as a television presenter before branching out into filmmaking. Her love of storytelling drove her to pursue a career behind the camera, where she rapidly made a name for herself. Oshin made her directing debut with the critically praised short film \"Till Death Do Us Part.\" The film received a lot of attention, demonstrating Oshin's remarkable directing abilities and paving the way for her future success. More awards and recognition include the Africa Magic Viewers' Choice Awards (AMVCA) in 2018 for New Money in the category of Best Director; the Africa Film Academy Awards (AMAA) in 2018 for <a href='https://www.imdb.com/title/tt7493274/?ref_=nm_knf_t_4' target=”_blank”>We Don't Live Here Anymore </a>in the category of Best Director; the Nigerian Entertainment Awards (NEA) in 2018 for the project \"We Don't Live Here Anymore in the category of Best Director, and many more follow.",
      "Her creative process is a well-crafted journey from the initial concept to the finished product. Whether she's working on an original story or a collaborative project, she begins by brainstorming with trusted creatives. She weaves the story with authenticity by conducting considerable personal investigation and drawing on real-life experiences and important facts. Oshin understands the significance of creating fascinating characters and arranging the narrative so that it coincides with her vision as a director. Throughout the process, she promotes collaboration and coordination, believing that great collaborations result in excellent films.",
      "Tope Oshin carefully evaluates different factors before beginning a new project or selecting a film. She seeks interesting screenplays with strong storytelling aspects that speak to her. She also considers the project's alignment with her artistic vision, its potential for social impact and creative discovery, as well as its financial feasibility and available resources. Collaboration and the ability of the members of the production team are also important elements. Oshin evaluates the project's target audience and market potential further while looking for opportunities for personal growth and new challenges.",
      "According to Tope, films play an important role in creating society, particularly in the empowerment of women. She is a firm believer in the ability of films to dispel prejudices, promote inclusivity, address social issues, and provide inspiring role models. Films may exhibit women in various and relevant positions, encouraging and empowering them in their own lives through representation and exposure. Films inspire society to examine long-held views and embrace a more equitable and diverse culture by challenging gender conventions. Tope focuses on how films start conversations, increase awareness, and advocate for women's rights and empowerment, ultimately sparking societal change. Furthermore, films create empathy and understanding, allowing audiences to relate to women's experiences and increasing support for women's empowerment programs. Films help to create a more inclusive and equal society by influencing cultural views.",
      "Oshin has met both unexpected issues and exceptional chances while working in the African film industry. One of the most crucial potentials is tapping into Africa's undiscovered stories and vibrant culture. African filmmakers, such as Oshin, can provide new viewpoints, shed light on underprivileged situations, and question social norms. However, the industry has several problems, including gender bias, cultural impediments, and a lack of funding and resources. Despite these challenges, African filmmakers are carving out niches for themselves, pushing boundaries, and contributing to the industry's growth and evolution. Their work lays the groundwork for future generations of filmmakers, ensuring that different views are heard.",
      "The importance of representation in the film industry, especially for women and underrepresented groups, cannot be stressed enough, and Oshin recognizes this. Representation in the African film industry is extremely valuable because it challenges stereotypes, empowers individuals, transforms narratives, and gives underrepresented groups prominence and respect. The industry may overcome damaging preconceptions and encourage more realistic and comprehensive depictions by displaying various personalities and stories.",
      "Women and underrepresented groups gain recognition, pride, and a sense of belonging through visibility. Seeing characters on TV who share their experiences, problems, and victories can motivate people to pursue their aspirations, overcome hurdles, and embrace their identities. Furthermore, representation enables the sharing of diverse narratives and points of view, challenging prevailing narratives and promoting a more inclusive and equitable society.",
      "More prospects for collaboration, representation in other media, and a stronger impact on society result from increased visibility and recognition in film. The African film industry plays an important role in cultural preservation and promotion by presenting varied cultures and traditions. It promotes the richness and diversity of African cultures, increasing audience knowledge and appreciation.",
      "However, it is critical to recognize that the film business still confronts issues in terms of gender representation and equitable chances. Gender equality must be promoted both in front of and behind the camera. Tope Oshin's dedication to supporting women and underrepresented groups through her work serves as a model for the industry to emulate.",
      "In film, one is only as good as their last project. For Oshin, <a href='https://www.imdb.com/title/tt15139698/?ref_=nm_knf_t_1' target=”_blank”>Here Love Lies</a> was released exclusively on Netflix in March 2023, and she states that it has been such a blessing in the responses it has received and how well the audience understood the film and its messaging. ‘Here Love Lies’ is a film about friendship, resilience, breaking stereotypes, love, and family. It chronicles the journey and story of a single parent and blogger, Amanda, who embarks on a trip to find love but gets into a whole entanglement that affects not just her romantic life but her entire existence as she knows it. Here Love Lies is streaming on Netflix worldwide, which is a great feat for the filmmaker.",
      "Tope Oshin is not only a gifted filmmaker but also a change agent. Her creative technique, cautious project selection, and commitment to representation have all had a significant impact on Nigerian cinema. She has demonstrated through her work that films have the potential to challenge norms, inspire audiences, and alter society. Tope Oshin has become a role model for budding filmmakers and an advocate for a more inclusive and varied film industry because of her tireless efforts to promote women and underrepresented groups. Tope Oshin would like to be remembered as a human being who used her gifts, talents, and personality to inspire change, beauty, strength, kindness, and God’s love in her world.",
    ],
    profileImage: require("assets/img/BlogsImages/Tope/profile.png"),
    bannerImage: require("assets/img/BlogsImages/Tope/5.png"),
    carouselImages: [
      require("assets/img/BlogsImages/Tope/4.png"),
      require("assets/img/BlogsImages/Tope/2.png"),
      require("assets/img/BlogsImages/Tope/3.png"),
      require("assets/img/BlogsImages/Tope/5.png"),
    ],
  },
  {
    id: 3,
    writers: "Mmapowana Masete",
    name: "Sihle Hlophe",
    trailerName: "Celebrating Her story Through Song",
    professionTitle: "Filmmaker| Director | Writer ",
    bannerSubTitle:
      "Sihle Hlophe is a SAFTA-winning filmmaker and a multi-degree earner. Over the last 12 years, her work has been recognised with a number of accolades.",
    youtubeLink: "https://www.youtube.com/watch?v=5YkAojPGcew",
    mainTitle:
      "Sihle Hlophe - If I don’t vibe with a story on an energetic level and it’s not aligned with my values, then that story is probably not for me to tell",
    paragraphs: [
      "Sihle Hlophe is a multi-award-winning director, screenwriter, and holder of multiple degrees. Her company, <a href='https://passionseedcommunications.co.za/' target=”_blank”> Passion Seed Communications</a>, is a film-based social venture that she founded and executive produces. Hlophe is an alumni of the Netherlands' IDFAcademy, Finland's North-South-South Student Exchange Program, and Canada's Hot Docs Blue Ice Group Story Lab. Her work has garnered various awards over the last 14 years, including two African Movie Academy Award Nominations for Best Documentary.",
      'Sihle grew up in KaNyamazane, a township on the outskirts of Nelspruit in the Mpumalanga province. "I was in every school play when I was younger. I was interested in art, history, music, and theatre. I was also a huge fan of Steven Spielberg, Spike Lee, and Hype Williams", Hlophe states. She wanted to be a fashion designer at first, then a psychologist. She is attracted by art as well as human nature, and she believes that directing allows her to blend all her interests. She says she has always been curious, and that others around her encouraged her inquisitive nature and voracious thirst for knowledge.',
      "”I grew up loving TV. I watched lots of cartoons and children’s films and that is where my love of filmmaking began”.  Sihle has no preference for storytelling methods; if a story resonates with her, she will tell it. She directs both fiction and nonfiction content, and when asked which she prefers, she always answers \"the story dictates”. The genre, stylistic approach, and so on are all influenced by the plot. I'm open to the possibilities offered by the story.",
      "The auteur attended her first international film festival in 2015, the Pan African Film Festival in Los Angeles. The auteur was thrilled to attend the opening night alongside legendary director Stanley Nelson and Mama Nike Davies-Okundaye. She remembers the red carpet packed with journalists, festival attendees, and filmmakers, making the event a dream come true. The organization and the fact that the journalists already had briefs with their names and countries of origin struck the auteur. She encountered surviving Black Panthers members at the cocktail party, who screamed 'Mandela, Mandela' as they posed for photos. This experience was a watershed moment in the auteur's life, strongly validating and encouraging her to pursue her dreams.",
      "She is the producer and director of the feature-length documentary, <a href='https://www.imdb.com/title/tt21932674/' target=”_blank”>‘Lobola, A Bride’s True Price’</a>, which won the Most Outstanding Documentary Project award at the Durban Film Mart in 2017. Hlophe directed, <a href='https://www.afridocs.net/watch-now/lindela-under-lockdown/' target=”_blank”>Lindela Under Lockdown in 2020</a>, which won the Best Documentary Award at the Cameroon International Film Festival and the Best Documentary Short Award at the South African Film & Television Awards (SAFTAs). In 2021, Hlophe directed and produced Celebrating Herstory Through Song in 2022. Hlophe has directed two feature-length Showmax Original made for-TV fiction films, <a href='https://www.showmax.com/eng/movie/e0xt36k4-pearls-of-wisdom' target=”_blank”>Pearls Of Wisdom</a> and <a href='https://www.showmax.com/eng/movie/ck6s7875-expiry-date' target=”_blank”>Expiry Date</a>. Hlophe is also the director of African Dreams premiered in 2021, a SAFTA-winning 13-part mocku-series that aired on SABC 1.",
      "For a long time, racism and sexism have been issues in the film industry. Positive changes have occurred in the last ten years, as evidenced by organizations such as SWIFT. Hlophe would like to see more women in technical roles like as sound recordists, gaffers, grips, editors, colourists, music composers, and so on, as well as more diversity and inclusion on our screens. \"Why, for example, are the majority of romantic leads on our screens thin and heterosexual?\" Why is it still uncommon to see a character speaking a minority language on mainstream television? We've still got a long way to go. We're still being cautious. We must continue to push the boundaries”. Sihle expresses her willingness to promote any minority language or minority group that is underrepresented in the media.",
      "Hlophe truly believes that storytelling is a deeply spiritual undertaking. “You are ordained by a higher power and in some instances, those who came before you, to tell a certain story and sometimes, that story can change lives and be a catalyst for social change. All of these things are beyond your control as a filmmaker. You make the film and put it out there… the rest is up to that higher power. I firmly believe that intentions and energy matter. If I don’t vibe with a story on an energetic level and it’s not aligned with my values, then that story is probably not for me to tell”.",
      "Hlophe co-produced <a href='https://www.imdb.com/title/tt10576452/' target=”_blank”>Sadla</a>, a narrative short film that premiered in 2020 at the Sundance Film Festival in the United States. Hlophe has written and directed two award-winning narrative short films,  <a href='https://www.imdb.com/title/tt4128166/?ref_=tt_rvi_tt_i_5' target=”_blank”>As I Am</a> in 2014 and  <a href='https://www.imdb.com/title/tt6727514/?ref_=tt_rvi_tt_t_5' target=”_blank”>Nomfundo</a> in 2017, which have been shown at over 40 film festivals worldwide. She has also worked as a scriptwriter/screenwriter and story liner on award-winning TV dramas like Lockdown, Scandal, Mutual Friends, and Broken Vows between 2012 and 2017. She has also worked part-time as a lecturer at AFDA in 2016 and the University of Johannesburg in 2018 and 2019.",
      "Sihle Hlophe would like to be remembered for being a beautiful soul who made beautiful, thought-provoking films that create social change.",
    ],
    profileImage: require("assets/img/BlogsImages/SihleHlophe/Profile.png"),
    bannerImage: require("assets/img/BlogsImages/SihleHlophe/Banner.png"),
    carouselImages: [
      require("assets/img/BlogsImages/SihleHlophe/1.png"),
      require("assets/img/BlogsImages/SihleHlophe/2.png"),
      require("assets/img/BlogsImages/SihleHlophe/3.png"),
      require("assets/img/BlogsImages/SihleHlophe/4.png"),
    ],
  },
  {
    id: 4,
    writers: "Happy Khumalo",
    name: "Vusi’Africa Sindane",
    trailerName: "Surviving Gaza",
    professionTitle: "Director | Writer ",
    bannerSubTitle:
      "There's three qualifications in Gaza a diploma for knife Gurus , a degree for murder and a masters for heist.",
    youtubeLink: "https://www.youtube.com/watch?v=ItMSbuu48mA",
    mainTitle:
      "Vusi’Africa Sindane - The film industry was not a decision to me but a natural progression",
    paragraphs: [
      "Vusi’Africa Sindane was born in kwaNdebele and raised in Witbank now Emalahleni - Mpumalanga by his mother who is a passionate writer and a poet.  Sindane says his mother’s writing about situations of others and of herself inspired him to be able to write about his feelings and others. This affirmation is evident in his award-winning works; <a href='https://www.youtube.com/watch?v=ItMSbuu48mA' target=”_blank”>Surviving Gaza (2022)</a>, <a href='https://www.youtube.com/watch?v=s0AGNiJYJ3s&t=6s' target=”_blank”>Letters of Hope (2019)</a>, <a href='https://www.youtube.com/watch?v=NL0fjUOdLvg' target=”_blank”>The empty Kraal (2017)</a> to name a few.",
      "The film industry is so broad that one is expected to develop enthusiasm before embarking on it. He fell in love with the film industry during the earliest stages of his life in primary school and coordinated several shows in his fourth grade. He stated that he was motivated by old stories including Ubambo Lwami (1989) by Percy Langa and many others. His power lies in his courage to express his thoughts and feelings. Additionally, his flexibility in writing and directing.",
      "He <a href='http://www.trialbymedia.tv/' target=”_blank”>won 5 major awards over 14 nominations</a>. These include Best Artistic Bravery award at the Durban International Film Festival (DIFF) for Artistic Bravery Awards, Best Director, Best Sound and Best Visual effects at the African Movie Academy Award (AMMA). As a young emerging filmmaker, being nominated over 14 times supports his inordinate creativity and dedication towards the film industry. He wrote and directed all the films amongst those he’s received awards for.  This shows Vusi’Africa’s dedication to his craft and his talent.  According to him, achieving this much in the film industry demonstrates that the society appreciates his work and not all young filmmakers would be this phenomenal.",
      "Sindane says the writing, directing, and editing compliments each other as it is a universal thing and it is most likely that directors would edit their own films regardless of them taking credit or not, due to the underlaying fact that each film already has an assigned editor. “I’ve edited all three of my films”, Sindane highlighted regarding his flexibility towards the filmmaking process in the film industry. “I’ve been blessed with the work that I’ve been doing, it’s been getting to the right eyes, and it’s been receiving the right attention”, said Sindane.",
      "“The gap between black and white filmmakers will always be an issue of contention, high contention for that matter, and the reason that this will be an issue of high contention is because of the historic reason that have in the past separated black filmmakers and white filmmakers.” Sindane further stated that it is an obvious fact that white filmmakers were in a better position, they were better privileged than black filmmakers, because they had better subsidy schemes and black filmmakers did not. “Which means that historically, if we are to focus, the future of black filmmakers and white filmmakers - white filmmakers are better positioned financially because of their historic advantage to position themselves, because of the financial resources that they have”, Sindane stressed when asked about the gap that apartheid  opened between black and white filmmakers in the film industry.",
      "“We need to start moving away from a dependency of public funding, we need to find a way to carve out a path towards private financing for our films”, Sindane indicated that such is critical to consider as a reliable route that can meet the public sector halfway in terms of consistently funding emerging voices. Sindane heightened that it is very critical that in the film production and in the creation, to consider how filmmakers move towards the ownership of the resources of production, so that a different position in the film value chain can shift to a different dimension.",
      "Sindane is fully aware of the historical impact on African filmmakers, which limits potential content creators to showcase their amazing creativity. “The imbalance of the industry gives African filmmakers a good donkey, cart with no harness”, says Vusi’Africa. The only way to redirect this wave is to be financially independent and establish private funding which shall bring in underprivileged black filmmakers into the field and close up the historical gap. “Government is overloaded and cannot fund enough”, said Vusi’Africa.",
      "As an independent filmmaker Vusi’Africa believes in the constant creation of beautiful work that travels, that speaks to mass audiences, and that reach borders globally. He says students in film school have already won half the battle and the rest of it will begin after school in the beginning of their career path. The black society rarely have access to film schools and such opportunities should not be taken for granted. He further stated that the black societies should be proactive in creating the industry and a beautiful catalogue that can occupy the position aimed at the industry.",
      "He encourages all students studying towards film in different film schools to realise the potential and voice they have in changing Africa through films. As he is actively involved with the community and changing perspectives of black society, the film industry has that power to change the world.",
    ],
    profileImage: require("assets/img/BlogsImages/VusiAfrica/VusiProfile.jpeg"),
    bannerImage: require("assets/img/BlogsImages/VusiAfrica/GazaBanner.png"),
    carouselImages: [
      require("assets/img/BlogsImages/VusiAfrica/5.png"),
      require("assets/img/BlogsImages/VusiAfrica/2.png"),
      require("assets/img/BlogsImages/VusiAfrica/3.png"),
      require("assets/img/BlogsImages/VusiAfrica/4.png"),
      require("assets/img/BlogsImages/VusiAfrica/1.png"),
    ],
  },
  {
    id: 5,
    writers:
      "Countries Machate, Happy Khumalo, Pheeha Tjale, & Mmapowana Masete",
    name: "Kizito Samuel Saviour",
    trailerName: "Kizito Samuel Saviour",
    professionTitle: "filmmaker",
    bannerSubTitle: "Shame Of Puberty",
    youtubeLink: "https://youtu.be/NlifSYYJ1i4",
    mainTitle:
      "Kizito Samuel Saviour - I’m the kind of filmmaker who doesn’t want to repeat or recycle any signatures or symbols in the films I do because I do not want to be predicted by someone else who has been following my works.",
    paragraphs: [
      "Tune into the podcast </br>",
      "<iframe src='https://drive.google.com/file/d/1Hx5H89wY6YFEfGsH55vCTv1wblv4rfR-/preview' width='640' height='100' allow='autoplay'></iframe>",
      "Kizito Samuel Saviour has worked in several fields in the film production including producing, Script writing, directing, cinematography and film editing, for films including <a href='https://youtu.be/ZdEQs9YYklg' target=”_blank”> NKWANZI’S SLIP (2023)</a>, <a href='https://youtu.be/2hUOhczfkcM' target=”_blank”>MANIA (2022)</a>, <a href='https://youtu.be/jBqwh84ANSE' target=”_blank”>Your Turn (2022)</a>, <a href='https://youtu.be/LCDjcxqFXn4' target=”_blank”>Catch Out (2021)</a>, <a href='https://youtu.be/smfnYV_nAJo' target=”_blank”>KEMI (2020)</a> and others, that picked up over 130 international nominations and over 30 international awards from film festivals and awarding platforms from all over the world.",
      "Kizito Samuel Saviour born as Kizito Samuel, is a Ugandan multi-award-winning filmmaker who majors in Producing, Script Writing and Directing. In late 2013 he joined Sky Limit Entertainment as an actor in Uganda where he was inspired to pursue screenwriting that sparked off his filmmaking journey. He is a Film & Video Production Tutor at ESOM School of Music in Uganda, a Film & Television Studies Mentor at the University of Limpopo in South Africa, and a mentor to several upcoming filmmakers and actors in both countries, and Africa at large.",
      "Kizito believes in a distinct vision of his films, hence he developed the courage to generalise in other fields of film production. Kizito’s influence led him to generalise in various fields because of the outcome of the work from his crew. It didn’t always meet his expectations. “I only learned editing and cinematography because I was tired of hiring people who get lazy with work and fail to bring out my vision the way I want it, so now I can hire a cinematographer or editor but if they mess up, I can comfortably step in and achieve that shot or that edit the way I want it,” he emphasised.",
      "This well-known Ugandan Filmmaker also actively participates in the development of African beginner filmmakers. He emphasised that Africa does not have enough studios to promote the film industry. “I would like to see more Film and TV Training institutions in the country that are working towards the goal of producing more trained human resource to work in the industry. I would like to see more cinemas being established in the country in various regions that can hardly access the currently existing cinemas. I’m looking forward to seeing establishments of standard film studios in Uganda for I believe that will add more value to the industry as well as creating more employment”, Kizito elaborated.",
      "He opened a <a href='https://samuelsaviour.wordpress.com/' target=”_blank”>Ugandan film themed blogsite </a>.  The blog is commonly known as a source for Ugandan cinema updates, and it is through this blog that he attained a role of “film critic“ in the film industry. He joined The Ghetto Film Project, an organisation that mentors underprivileged kids into film, and he acquired more filmmaking knowledge and mentorship.",
      "Kizito has directed 10 feature films and five short films. Although this has been a tremendous experience for Kizito, he emphasised that his journey had ample obstacles due to lack of funding. “As a director when the project has less funding I’m limited because that means there will be less resources to tell the story”.  He says working with less resources affected him, however it gave room for creativity. The most critical challenges he faced had to do with budget for the projects, and due to the limited skills of his producers, cast and crew, the projects would be much more challenging than anticipated.",
      "Kizito states that every film he produced has unique signatures and symbols, and repetition is not in his bucket list. “I’m the kind of filmmaker who doesn’t want to repeat or recycle any signatures or symbols in the films I do because I don’t want to be predicted by someone else who has been following my works,” he highlights.  Kizito is versatile in themes and genres, “I’ve served greatly with family dramas and dark African stories that work with family conflict, love, incest, good meets evil, poverty, haves and have nots, betrayal, togetherness, no bad deed goes unpunished, and for every story I make.”",
      "He considers significant symbols for each story world, and the films  he directs have an intense focus on characters, expressions, and their emotions, considering the bond creation and connection between the viewers and characters on screen. He believes that racking focus, tracking their faces to highlight their feelings as being projected to viewers on the characters personalities can sink into the viewers mind so they can empathise with the characters.",
      "He believes the key to mastery is to always be a student, learn from every moment, and find your purpose in life. Your purpose should be to love and share as well as serve the world, and you should find ways to share and serve it. Violence is never a solution to problems. Kizito further believes that Africa could be a leading continent in the film industry, only if the government and other involved entities put a hand in terms of funding and providing necessary resources to beginners and experienced filmmakers in the continent.",
      "Kizito would like to be remembered in Africa as a filmmaker who told inspirational stories that impacted positively on people’s lives, because of the truth and authenticity he portrayed in every story he produced. He urges filmmakers not to settle for less when it comes to storytelling regardless of the limited resources they possess. His greatest wish is to collaborate and create a bond amongst other African storytellers while enhancing their abilities to do better.",
    ],
    profileImage: require("assets/img/BlogsImages/KizitoBlogImages/KizitoProfilePic.png"),
    bannerImage: require("assets/img/BlogsImages/KizitoBlogImages/banner.png"),
    carouselImages: [
      require("assets/img/BlogsImages/KizitoBlogImages/1.png"),
      require("assets/img/BlogsImages/KizitoBlogImages/2.png"),
      require("assets/img/BlogsImages/KizitoBlogImages/3.png"),
      require("assets/img/BlogsImages/KizitoBlogImages/4.png"),
      require("assets/img/BlogsImages/KizitoBlogImages/5.png"),
      require("assets/img/BlogsImages/KizitoBlogImages/6.png"),
    ],
  },
  {
    id: 6,
    writers: "Dan",
    name: "Reabetswe Rea Moeti",
    trailerName: "Mma Moeketsi",
    professionTitle: "filmmaker",
    bannerSubTitle: "Based on a true event, the Marikana Massacre.",
    youtubeLink: "https://www.youtube.com/watch?v=ro23zJUzwls",
    mainTitle:
      "Reabetswe Rea Moeti - I want to exercise my voice and leave a mark",
    paragraphs: [
      "The ambitious African writer was motivated to tell her story to the world when she first met Malume Sammy Fever from Mabopane,Pretoria who helped her realize her vision. Rea says there were various roles in film that she could have chosen while studying but directing is what came naturally to her. “I didn't like the administrative aspect of producing so I leaned towards the creative storytelling functions like editing, cinematography and directing”, she explained.",
      "Reabetswe Moeti is an award winning film director and writer hailing from Ga-Rankuwa, Pretoria and she made her first short film when she was still in high school. Moeti decided to chase her dream by pursuing her film studies at Cape Peninsula University of Technology, Cape Town and has been in it since.",
      "When we ask how Moeti keeps on beating the odds that historically excluded black women in the industry, she emphasizes the importance of putting in hard work in what she does. However, she clarifies that one has to be strategic and work smart even though it is not easy. Rea believes the best way is to constantly look for new opportunities even when you don't think they apply to you. “I'm always researching, looking for opportunities, gaps in our industry and innovative ways to overcome the challenges we face”, elaborates Moeti.",
      "One of her soul-stirring films inspired by a true event, the Marikana Massacre is called Mma Moeketsi. This film emanates from what happened in Marikana but it also speaks to the story of a real person called Mma Moeketsi. Rea says the story is close to her heart because Mma Moeketsi has worked for her family as a domestic worker for about 14 years now. “Her story was important to me because her son was at the mines when the strike happened so I wanted to tell her story to the world from her perspective”, she alluded.",
      "Rea has ventured into many other film related activities including writing and directing TV shows which has helped to sharpen her skills and develop an instinct for directing. There are other films that are part of Moeti’s craft like The Virgin Vegan. When we asked which of her films were her favourites, Rea mentioned that The Virgin Vegan and Mma Moeketsi have always been her best films thus far. The renounced filmmaker says she is getting better at storytelling, communicating her point through visuals, sound design and good editing.",
      "In any case, the struggle continues for Moeti as she is developing two films with MNET movies at the moment. “I am looking forward to finishing the writing process so that we can film those two features”, she confirmed. Rea says she will keep looking forward to exercising her voice as a storyteller and making her mark as a director.",
    ],
    profileImage: require("assets/img/BlogsImages/Marikana1.jpeg"),
    bannerImage: require("assets/img/BlogsImages/marikana.jpg"),
    carouselImages: [
      require("assets/img/BlogsImages/Marikana1.jpeg"),
      require("assets/img/BlogsImages/Marikana2.jpg"),
    ],
  },
  {
    id: 7,
    writers: "Dan",
    name: "Mandla Dube",
    trailerName: "Kalushi",
    professionTitle: "filmmaker",
    bannerSubTitle: "The Story of Solomon Mahlangu",
    youtubeLink: "https://youtu.be/LmlrL4lh2K0",
    mainTitle: "Mandla Dube - Solomon’s spirit chose me to tell his story",
    paragraphs: [
      "Mandla Walter Dube is an award winning and internationally acclaimed director and producer. Born and bred in Mabopane, North West of Tshwane, Dube says he fell in love with cinema since he was seven years old or so. When Dube did his undergraduate studies at Clark Atlanta University (CAU), he was hungry to tell stories and so he studied African history. Dube says this opened a whole universe of African stories in the diaspora which he was willing to learn and embrace.",
      "The above mentioned is just a glimpse of what Dube achieved before he could be known and little did the world know that he would set foot at the Tshwane University of Technology. He would become a lecturer with a keen focus on cinematography and that was the beginning of a totally different and even more interesting journey. As Dube was lecturing at the institution, his studies did not stop as he began consulting in Cinematography and Heritage Management. Dube would soon obtain a Master of Arts in cinematography from American Film Institute, in Los Angeles. ” In Los Angeles my visual storytelling style was sharpened as a cinematographer working for some of the great studio cinematographers”, says Dube.",
      "The acclaimed filmmaker has since done a lot of storytelling which includes documentaries, theatre and feature films. A docudrama, Sobukwe: A Great Soul, won SAFTA’s 2013, Kalushi, (the Stage play), at the South African State Theatre, The Rivonia Trial stage play. He also produced music videos for Sony, City of Tshwane, and the South African Post Office. Dube’s work in feature films as an operator & camera assistant includes The Italian Job, Men in Black 2, Bio Force 1, Money Monster, Drop Squad and Umthunzi we Ntaba. Dube’s experience in Short films includes Badger, Sunset Tuxedo, As I Am and A Single Rose.",
      "In addition to his qualification in Heritage Management, Dube’s passion and commitment to South African Arts and Heritage has manifested in productions in various media, including a documentary on The Reburial of the Mapungubwe Remains, the photographic exhibition In Pursuit of Liberty in association with the Nelson Mandela Foundation.",
      "His recent works are Kalushi a feature film released last year to great global success at major festivals (Luxor, Los Angeles, Zanzibar, and New York City). He is in development with The Legends of Freedom (Silverton Siege, Yasuke, Pushkin, Rivonia Trial). He was instrumental in the formation of Independent Black Filmmakers Collective (IBFC) as a co-founding member of the working committee. His passion is family and farming.",
    ],
    profileImage: require("assets/img/BlogsImages/mandlaDubeProfile.jpg"),
    bannerImage: require("assets/img/BlogsImages/KalushiBanner.png"),
    carouselImages: [
      require("assets/img/BlogsImages/KalushiBanner2.jpeg"),
      require("assets/img/BlogsImages/KalushiBanner1.jpeg"),
    ],
  },
  {
    id: 8,
    writers: "Dan",
    name: "Molatelo Mainetje",
    trailerName: "Womb-man",
    professionTitle: "filmmaker",
    bannerSubTitle: "When babies dont come",
    youtubeLink: "https://youtu.be/Iu5IQvuTpnM",
    mainTitle:
      "Molatelo Mainetje - I want to be a driver in the broadcasting of ‘marginalised’ content.",
    paragraphs: [
      "Molatelo Mainetje is an award-winning television news and current affairs producer, video journalist and documentary filmmaker. She has been in the television industry for many years, and holds a Post-Graduate Diploma in Television Production. Molatelo is the founder of Bolobathaba Filmmaking Project in rural GaModjadji in Limpopo Province where she hails from.",
      "The award winning filmmaker did her first documentary in 2005, A place of her own, which was more about the role of women in African churches looking at whether or not they are recognized. This was the beginning of an even more interesting journey of her life. It is through the spark that mushroomed then that Molatelo has produced, written, directed and edited several documentary films in South Africa and the continent of Africa. Her work has been broadcast locally and internationally over the years.",
      "Molatelo says she joined the filmmaking industry in 2001 when it seldom happened that a woman could direct a film and this came with its challenges.  “The challenge was that I had to work and fight twice as hard unlike what a man would do in everything that I did”, she alluded. The filmmaker elaborated that even the whole exercise of being a filmmaker came with a lot of effort that had to be put in each and every day.",
      "The renowned filmmaker was behind the production of a documentary called When babies don’t come and a short film, Womb-man and they are both about attempting and failing at getting pregnant. These projects have a special attachment to a real life situation of Molatelo where she tried to fall pregnant in her real life but could not. In any case, the struggle continues for Molatelo as she hopes to do more projects in the future.",
      "She is now working towards becoming an international filmmaker and a driver of a television channel that will broadcast ‘marginalised’ content. Molatelo’s target market is the currently neglected audience where the poor are situated. “I’m moving from being just a content creator to a broadcaster”, she explained. However, Molatelo also has her eyes on skills development programmes that she runs in partnership with other interested parties. She is also working on a certain film that will be released soon and some documentaries whose names she could not reveal yet.",
    ],
    profileImage: require("assets/img/BlogsImages/WombManProfile.JPG"),
    bannerImage: require("assets/img/BlogsImages/WombManBanner.JPG"),
    carouselImages: [
      require("assets/img/BlogsImages/WombMan2.JPG"),
      require("assets/img/BlogsImages/WombMan1.JPG"),
    ],
  },
  {
    id: 8,
    writer: "Dan",
    name: "Comfort Arthur",
    trailerName: "Black Barbie",
    professionTitle: "filmmaker",
    bannerSubTitle: "Comfort Arthur",
    youtubeLink: "https://youtu.be/_SxaLHVu5FI",
    mainTitle:
      "Comfort Arthur - I would like to take my storytelling processes to the next level - to push the boundaries ",
    paragraphs: [
      "Comfort Arthur is a British Born Ghanaian with a Bachelor’s Degree in Graphic Design from Central St Martins and a Master’s Degree in Animation from the Royal College of Arts. In December 2012, she moved to Ghana and now works as a freelance Editor, Illustrator, Graphic Designer, and an Animator. She has worked on several award-winning films in Ghana.",
      "In 2015, her short animation film The peculiar Life of a Spider (2015) garnered a nomination at the Ghana Movie Awards (GMA) and Africa Movie Academy Awards (AMAA). She has won several awards for her short animation film Imagine (2016), which won best Animation in 2016. Her latest film Black Barbie (2016) won Best Poetry film at the Real Time festival and Best Animation at Ghana Movie Awards, both in 2016.",
      "What made you decide that you wanted to do animation as a career and which was the first animation project you directed?",
      "I knew as a child I was very creative, but I did not know exactly what I wanted to do. The closest thing next to Animation was Graphic Design, but it focussed on illustrations. On closer inspection, I knew I did not want to spend the rest of my life drawing, so Animation became the next thing I discovered.  After I graduated with a Master’s degree in Animation in London, I thought “great I’ve got a degree, I can now work in the industry”. That was not the case, for I ended up working in a supermarket for seven long years. Those times were frustrating and depressing. In 2012, my younger sister saw an advert that a production company in Ghana was looking for a Film Editor.",
      "That’s how I left London and moved to Ghana to become a Film Editor. I learned so much about filmmaking and even how to edit films. In 2015, I wanted to work on my animation projects, so I left and became a freelancer. I started working on my own personal projects. My first produced film “The Peculiar life of a spider (2015)” got me my first AMMA nomination. It also gave me the confidence to make more short films, as the following year, I produced “Imagine (2016)” and “Black Barbie (2016)”. We screened them all over the world. “Black Barbie (2016)” gave me my first directorial award at the Black Star International Film Festival (BSIFS).",
      "Given the fact that it has not been easy for women Animators in the past, how did you climb to the top regardless of the challenges?",
      "I have a supportive close circle of creatives that have pushed me and supported me in my career. Many have been the opposite sex that have championed me and also helped me to thrive in the industry. There are many female animators around the continent producing amazing work, which makes me so happy. With that being said, we still have a long road.  We need to teach both men and women about the power of animation.",
      "The lack of willingness by other animators and digital artists to collaborate sometimes on projects has been a hindrance to engagement on wider projects. As a filmmaker, my biggest challenge has been the inability to raise funding for some of my animation projects.",
      "One of your best films is “Black Barbie”; please tell us what motivated you to do this film and what basic message were you spreading across?",
      "I initially wrote Black Barbie for my younger cousin, who was battling with low self-esteem because of her skin colour. When she confided in me that, she wanted to bleach her skin, like my cousin - many were battling with coming to terms with their skin colour. So I wrote Black Barbie with her in mind, so that she could read and understand that she was not alone and that I too had once hated my skin tone. I think that some of the best films created are from our personal experiences. During my degree, my thesis was about how animation can be therapeutic, so making this film was like going to therapy. I had to go back to my past and memories and feelings, which helped me talk to my cousin and advise her.",
      "Of all the films that you have directed throughout your career; which one is your favourite and why? ",
      "I call my favourite project “I’m Living in Ghana Get Me Out of Here 2017”. I wanted to prove that you can make a successful web series on a tight budget, and so I spent 3 months creating this personal experience of my time living in Ghana. I loved making this film as I wanted viewers to relate and understand the complexities of having dual cultures. The web series is on Kweli TV.  You can sign up and watch it here…",
      "https://www.kweli.tv/programs/im-living-in-ghana-get-me-out-of-here/",
      "What advice could you give to young women who aspire to become Film Directors or Animators?",
      "Don’t give up! Being a filmmaker is not glamorous, as many people believe it to be. In the world, getting money to fund films is daunting and frustrating. When I am out of work, I work on my own personal projects. I also use those quiet times to learn new skills. It is important to always learn to pick up new skills. Animation is a new idea to the market in Africa, so it has become a gradual thing for companies that are doing animated commercials. Having another skill is fundamental, I am not only an animator, but I also edit and act when animation jobs dry up.",
      "Are there any film related projects that you are working on or will work on soon?",
      "Given the fact that films are evolving.  I am exploring the use of Virtual Reality (VR) and Augmented Reality (AR) in storytelling. I have been storytelling across several mediums from traditional picture book illustrations to comics and animation. I would like to take my storytelling processes to the next level, to push the boundaries and fuse these areas together to create some unique and engaging work using VR and AR.",
    ],
    profileImage: require("assets/img/BlogsImages/BlackBarbie1.jpg"),
    bannerImage: require("assets/img/BlogsImages/blackbarbie.jpg"),
    carouselImages: [
      require("assets/img/BlogsImages/blackbarbie.jpg"),
      require("assets/img/BlogsImages/BlackBarbie2.jpg"),
    ],
  },
];
